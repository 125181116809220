import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Gallery from 'components/gallery';


const Birthday = ({ data }) => (
  <Layout>
    <Head pageTitle={data.birthdayJson.title} />
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.birthdayJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>

    <Gallery items={data.birthdayJson.gallery} />
    <div style={{ height: '50vh' }} />
  </Layout>
);

Birthday.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Birthday;

export const query = graphql`
  query BirthdayQuery {
    birthdayJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
